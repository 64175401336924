import React from "react";
import { TrainerCardSkeleton } from "./trainer-card-skeleton";

export const TrainerGridSkeletons = () => (
  <div className="trainer-grid">
    {Array(6)
      .fill(0)
      .map((_, index) => (
        <React.Fragment key={index}>
          <TrainerCardSkeleton />
        </React.Fragment>
      ))}
  </div>
);

"use client";
import { ReactNode } from "react";

import { classNames } from "@/utils/styling-utils";
import { VideoPlayer } from "./video-player";
import {
  ImagePartsFragment,
  MediaPartsFragment,
  ResponsiveMedia,
  ResponsiveMediaPartsFragment
} from "@/generated/client.generated";
import { useIsDesktop } from "@/hooks/use-is-desktop";
import { WidthType } from "../layout/layout.model";
import { Image } from "@/components/ui/image";
import { Link } from "../base/link";

interface MediaAllProps {
  openUrlInNewTab?: boolean;
  url?: string;
}

interface MediaProps extends MediaAllProps {
  autoplay?: boolean;
  controls?: boolean;
  fallbackAlt: string;
  gradient?: boolean;
  media?: MediaPartsFragment | ResponsiveMediaPartsFragment | null;
  priority?: boolean; // specific for Image
  width?: WidthType | number;
}

export const Media = ({
  autoplay,
  controls,
  fallbackAlt,
  gradient,
  media,
  openUrlInNewTab,
  priority = false,
  url,
  width
}: MediaProps) => {
  const isDesktop = useIsDesktop();

  if (!media) {
    return <></>; // Henriette, hva gjør vi her?
  }

  // Transform media of type MediaPartsFragment to ResponsiveMediaPartsFragment
  const responsiveMedia: ResponsiveMediaPartsFragment =
    media.__typename !== "ResponsiveMedia" ? ({ desktop: null, mobile: media } as ResponsiveMedia) : media;

  const mediaToDisplay: MediaPartsFragment | null =
    isDesktop && responsiveMedia.desktop ? responsiveMedia.desktop : responsiveMedia.mobile;

  const videoThumbnail: ImagePartsFragment | null =
    mediaToDisplay?.__typename === "Video"
      ? isDesktop
        ? responsiveMedia.thumbnailForDesktopVideo
        : responsiveMedia.thumbnailForMobileVideo
      : null;

  // fallbackAlt is only used if the alternative text is not set in Strapi for the specific media file
  const alternativeTextToDisplay = mediaToDisplay?.alternativeText ?? fallbackAlt;
  const caption = mediaToDisplay?.caption ?? "";

  // svg-filer må ha en definert bredde for å bli vist i Firefox
  // gjør om bredde satt i pixels i Strapi til rem
  const setWidth = width && width !== "full" ? `${width / 10}rem` : undefined;

  return (
    <div
      className={classNames("media", width === "full" && "media__width--full")}
      style={{
        ...(setWidth && { maxWidth: setWidth, width: "100%" })
      }}
    >
      <MediaWrapper alt={alternativeTextToDisplay} url={url} openUrlInNewTab={openUrlInNewTab}>
        {mediaToDisplay?.__typename === "Video" ? (
          <VideoPlayer
            autoplay={autoplay}
            caption={caption}
            url={mediaToDisplay?.url}
            thumbnail={videoThumbnail ?? undefined}
            controls={controls}
          />
        ) : (
          responsiveMedia.mobile?.url && (
            <Image
              alt={alternativeTextToDisplay}
              caption={caption}
              gradient={gradient}
              src={responsiveMedia.mobile.url}
              srcDesktop={responsiveMedia.desktop?.url}
              priority={priority}
              placeholder={mediaToDisplay?.placeholder ?? undefined}
            />
          )
        )}
      </MediaWrapper>
    </div>
  );
};

interface MediaWrapperProps extends MediaAllProps {
  alt: string;
  children: ReactNode;
}

const MediaWrapper = ({ children, openUrlInNewTab, url }: MediaWrapperProps) =>
  url ? (
    <Link href={url} newTab={openUrlInNewTab} textDecoration="none">
      {children}
    </Link>
  ) : (
    <>{children}</>
  );

"use client";
import React, { useState } from "react";
//TODO remove workaround based on https://github.com/cookpete/react-player/issues/1436
import { PauseCircleIcon, PlayCircleIcon } from "../icons/icons";
import { IconButton } from "./icon-button";
import { Text } from "@/components/ui/text";
import { VideoJsonLd } from "next-seo";
import ReactPlayer from "react-player";
import { Image } from "@/components/ui/image";
import { ImagePartsFragment } from "@/generated/client.generated";

interface VideoPlayerProps {
  autoplay?: boolean;
  caption?: string;
  controls?: boolean;
  url: string;
  thumbnail?: ImagePartsFragment;
}

export const VideoPlayer = ({ caption, controls = true, url, thumbnail }: VideoPlayerProps) => {
  const [playState, setPlayState] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const fallbackImage = thumbnail?.url ? (
    <Image
      alt={thumbnail.alternativeText || "thumbnail"}
      src={thumbnail.url}
      placeholder={thumbnail.placeholder ?? undefined}
    />
  ) : undefined;

  return (
    <div className="video-player">
      <VideoJsonLd useAppDir={true} url={url} thumbnailUrl={thumbnail?.url} description={caption} />
      <ReactPlayer
        muted={true}
        onReady={() => setIsReady(true)}
        playing={playState}
        loop={true}
        controls={controls}
        width="100%"
        height="100%"
        url={url}
        fallback={fallbackImage}
        light={fallbackImage}
        playIcon={
          <div className="video-player__icons">
            <IconButton
              ariaLabel="Play"
              onClick={() => {
                setPlayState(true);
              }}
            >
              <PlayCircleIcon />
            </IconButton>
          </div>
        }
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
              playsInline: true
            },
            hlsOptions: {
              capLevelToPlayerSize: true,
              startLevel: -1,
              useDevicePixelRatio: true,
              useNetworkInformationApi: true
            }
          }
        }}
        playsinline
      />
      {caption && <Text color="gray" size="small" value={caption} />}
      {!controls && isReady && (
        <div className="video-player__icons">
          {playState ? (
            <IconButton
              ariaLabel="Pause"
              onClick={() => {
                setPlayState(false);
              }}
            >
              {/* TODO: sette farge på ikoner etter textTheme */}
              <PauseCircleIcon />
            </IconButton>
          ) : (
            <IconButton
              ariaLabel="Play"
              onClick={() => {
                setPlayState(true);
              }}
            >
              <PlayCircleIcon />
            </IconButton>
          )}
        </div>
      )}
    </div>
  );
};

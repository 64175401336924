import React from "react";
import { FormatBaseProps } from "./ui.model";
import { classNames } from "@/utils/styling-utils";

interface TextWithChildrenProps extends FormatBaseProps {
  noWrap?: boolean;
  shadow?: boolean;
  truncate?: boolean;
  underline?: boolean;
  gradient?: boolean;
  displayInline?: boolean;
}

const cssClasses = (property: string, value?: string) => {
  if (!value) {
    return;
  }

  const className = `text-with-children__${property}--${value}`;
  return className;
};

const TextWithChildren: React.FC<React.PropsWithChildren<TextWithChildrenProps>> = ({
  align,
  children,
  color,
  noWrap,
  shadow,
  size,
  style,
  textDecoration,
  truncate,
  underline,
  uppercase,
  weight,
  gradient,
  displayInline = false
}) => {
  const cssAlignClass = cssClasses("align", align);
  const cssColorClass = cssClasses("color", color);
  const cssSizeClass = cssClasses("size", size);
  const cssStyleClass = cssClasses("style", style);
  const cssWeightClass = cssClasses("weight", weight);
  const cssTextDecorationClass = cssClasses("text-decoration", textDecoration);

  const cssClassesAsString = classNames(
    "text-with-children",
    cssAlignClass,
    cssColorClass,
    cssSizeClass,
    cssStyleClass,
    cssWeightClass,
    cssTextDecorationClass,
    noWrap && "text-with-children--no-wrap",
    shadow && "text-with-children--shadow",
    truncate && "text-with-children--truncate",
    underline && "text-with-children--underline",
    uppercase && "text-with-children--uppercase",
    gradient && "text-with-children--gradient"
  );
  if (displayInline) {
    return <span className={cssClassesAsString}>{children}</span>;
  }
  return <div className={cssClassesAsString}>{children}</div>;
};

export default TextWithChildren;

import { ReactNode } from "react";
import { classNames } from "@/utils/styling-utils";

interface ContentContainerProps {
  backgroundColor?: string;
  children: ReactNode;
  horizontalPlacement?: "center" | "left";
  id?: string;
  width?: "xx-small" | "x-small" | "small" | "medium" | "large" | "x-large";
}

const cssClasses = (property: string, value?: string) => {
  if (!value) {
    return;
  }

  const className = `content-container__${property}--${value}`;
  return className;
};

export const ContentContainer = ({
  backgroundColor,
  children,
  horizontalPlacement,
  id,
  width
}: ContentContainerProps) => {
  const cssJustifyClass = cssClasses("horizontal-placement", horizontalPlacement);
  const cssWidthClass = cssClasses("width", width);

  return (
    <div
      className={classNames(
        "content-container",
        cssJustifyClass,
        cssWidthClass,
        backgroundColor && "content-container--padding"
      )}
      id={id}
      style={{ backgroundColor }}
    >
      {children}
    </div>
  );
};

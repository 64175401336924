import { ReactNode } from "react";

import { classNames } from "@/utils/styling-utils";

interface FullWidthContainerProps {
  backgroundColor?: string;
  children: ReactNode;
  paddingOnMobile?: boolean;
  spanTopMargin?: boolean;
  defaultBgColorDark?: boolean;
}

export const FullWidthContainer = ({
  backgroundColor,
  children,
  paddingOnMobile,
  spanTopMargin,
  defaultBgColorDark = false
}: FullWidthContainerProps) => {
  const fallbackBackgroundcolor = backgroundColor ? undefined : defaultBgColorDark ? "dark" : undefined;

  return (
    <div
      style={{ backgroundColor }}
      className={classNames(
        "full-width-container",
        (backgroundColor || fallbackBackgroundcolor) && "full-width-container--background-color",
        fallbackBackgroundcolor && `full-width-container--background-color--${fallbackBackgroundcolor}`,
        paddingOnMobile && "full-width-container--padding-on-mobile",
        spanTopMargin && "full-width-container--span-top-margin"
      )}
    >
      {children}
    </div>
  );
};
